import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    Switch,
    Button,
    Menu
} from '@mui/material';
import React, {useState} from "react";
import AddIcon from '@mui/icons-material/Add';

import Move from './Move'
  
const SCREENSHOTS = process.env.REACT_APP_SCREENSHOTS_URL

const Steps = (props) => {

    const addStep = (event, index) => {
        var new_step = {}
        if (event === "go") {
            new_step = {
                "event": "go",
                "url": "",
                "wait-to-load": 1,
                "extract": false
            }
        } else if (event === "click") {
            new_step = {
                "event": "click",
                "continue_if_fail": false,
                "expresion": "",
                "wait-to-load": 1,
                "extract": false
            }
        } else if (event === "type") {
            new_step = {
                "event": "type",
                "expresion": "",
                "text": "",
                "wait-to-load": 1,
                "extract": false
            }
        } else if (event === "scroll") {
            new_step = {
                "event": "scroll",
                "y": "end",
                "wait-to-load": 1,
                "extract": false
            }
        } else if (event === "file") {
            new_step = {
                "event": "file",
                "expresion": "",
                "file": "cam.mp4",
                "wait-to-load": 1,
                "extract": false
            }
        }  else if (event === "system_variable") {
            new_step = {
                "event": "system_variable",
                "expresion": "",
                "variable_name": "",
                "wait-to-load": 1,
                "extract": false
            }
        } else if (event === "status_variable") {
            new_step = {
                "event": "status_variable",
                "expresion": "",
                "variable_name": "",
                "wait-to-load": 1,
                "extract": false
            }
        } else if (event === "key_press") {
            new_step = {
                "event": "key_press",
                "key": "enter",
                "wait-to-load": 1,
                "extract": false
            }
        }   else if (event === "zoom_out") {
            new_step = {
                "event": "zoom_out",
                "expresion": "",
                "wait-to-load": 1,
                "extract": false
            }
        }  

        let new_test = {...props.test}
        new_test.navigate.splice(index, 0, new_step)
        props.setTest(new_test)
    }

    const moveStep = (old_index, new_index) => {
        let new_test = {...props.test}
        new_test.navigate.splice(new_index, 0, new_test.navigate.splice(old_index, 1)[0]);
        props.setTest(new_test)
    }

    const deleteStep = (index) => {
        let new_test = {...props.test}
        new_test.navigate.splice(index, 1);
        props.setTest(new_test)
    }

    return (
        <Box className="test_editor_form">
                {
                    props.test.navigate.map((step, index) => {
                        return (
                            <Step
                                test={props.test}
                                setTest={props.setTest}
                                step={step}
                                index={index}
                                moveStep={moveStep}
                                deleteStep={deleteStep}
                                addStep={addStep}
                                key={index}
                            />
                        )
                        }
                    )
                }

                {
                    props.test.navigate.length == 0 ?
                        <Box className="test_editor_add_step">
                            <h3>Añadir paso</h3>
                            <Box className="test_editor_add_step_buttons_row">
                                <Button variant="outlined" color="primary" style={{marginRight: 10}} onClick={() => {addStep("go",0)}}>Ir a URL</Button>
                                {/* <Button variant="outlined" color="primary" style={{marginRight: 10}} onClick={() => {addStep("click",0)}}>Clicar</Button>
                                <Button variant="outlined" color="primary" style={{marginRight: 10}} onClick={() => {addStep("type",0)}}>Escribir</Button>
                                <Button variant="outlined" color="primary" style={{marginRight: 10}} onClick={() => {addStep("scroll",0)}}>Scroll</Button>
                                <Button variant="outlined" color="primary" style={{marginRight: 10}} onClick={() => {addStep("file",0)}}>Archivo</Button>
                                <Button variant="outlined" color="primary" style={{marginRight: 10}} onClick={() => {addStep("system_variable",0)}}>Variable de sistema</Button>
                                <Button variant="outlined" color="primary" style={{marginRight: 10}} onClick={() => {addStep("status_variable",0)}}>Variable de estado</Button>
                                <Button variant="outlined" color="primary" style={{marginRight: 10}} onClick={() => {addStep("zoom_out",0)}}>Zoom out</Button>
                                <Button variant="outlined" color="primary" onClick={() => {addStep("key_press",0)}}>Tecla</Button> */}
                            </Box>
                        </Box>
                    : <></>
                }
                
        
        </Box>
    )
}

const Step = (props) => {
        
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    };
    const handleClose = () => {
        setAnchorEl(null)
    };

    const stepName = (event) => {
        if (event === "go") return("Ir a URL")
        else if (event === "click") return("Hacer click")
        else if (event === "type") return("Escribir")
        else if (event === "scroll") return("Hacer scroll")
        else if (event === "file") return("Subir archivo")
        else if (event === "system_variable") return("Variable de sistema")
        else if (event === "status_variable") return("Variable de estado")
        else if (event === "key_press") return("Pulsar tecla")
        else if (event === "zoom_out") return("Hacer zoomout")
    }

    return (
        <Box>
            <Box className="test_editor_form_step">
                {
                    props.index === props.test.fail_step ?
                        <div style={{width: 20, height: 20, backgroundColor: "red", borderRadius: 10}}></div>
                    : <></>
                }
                <Box className="test_editor_form_step_row">
                    <Box className="test_editor_form_title">
                        <h3>Paso {props.index} - {stepName(props.step.event)}</h3>
                        
                    </Box>
                    
                    <Move index={props.index} steps={props.test.navigate.length} move={props.moveStep} delete={props.deleteStep}/>
                </Box>

                {
                    props.step.event === "go" ? 
                    <>
                        <Box className="test_editor_form_step_row">
                        <Box className="test_editor_form_input">
                            <TextField
                            id="url"
                            label="URL"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step.url}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index].url = event.target.value
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        </Box>
                        <Box className="test_editor_form_step_row">
                        <Box className="test_editor_form_input">
                            <TextField
                            id="wait-to-load"
                            label="Espera para cargar"
                            type="number"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step["wait-to-load"] ? props.step["wait-to-load"] : 0}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index]["wait-to-load"] = event.target.value.length === 0 ? 0 : parseInt(event.target.value)
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        <Box className="test_editor_form_input">
                            <FormControlLabel
                            control={
                                <Switch
                                color="primary"
                                checked={props.step.extract}
                                onChange={event => {
                                    let new_test = {...props.test}
                                    new_test.navigate[props.index].extract = event.target.checked
                                    props.setTest(new_test)
                                }}
                                />
                            }
                            label="Capturar"
                            />
                        </Box>              
                        </Box>
                    </>
                    : <></>
                }

                {
                    props.step.event === "click" ? 
                    <>
                        <Box className="test_editor_form_step_row">
                        <Box className="test_editor_form_input" style={{"flex": 4}}>
                            <TextField
                            id="expresion"
                            label="Expresion"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step.expresion}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index].expresion = event.target.value
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        </Box>
                        <Box className="test_editor_form_step_row">
                        <Box className="test_editor_form_input">
                            <TextField
                            id="wait-to-load"
                            label="Espera para cargar"
                            type="number"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step["wait-to-load"] ? props.step["wait-to-load"] : 0}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index]["wait-to-load"] = event.target.value.length === 0 ? 0 : parseInt(event.target.value)
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        <Box className="test_editor_form_input">
                            <FormControlLabel
                            control={
                                <Switch
                                color="primary"
                                checked={props.step.extract}
                                onChange={event => {
                                    let new_test = {...props.test}
                                    new_test.navigate[props.index].extract = event.target.checked
                                    props.setTest(new_test)
                                }}
                                />
                            }
                            label="Capturar"
                            />
                        </Box>
                        <Box className="test_editor_form_input">
                            <FormControlLabel
                            control={
                                <Switch
                                color="primary"
                                checked={props.step.continue_if_fail}
                                onChange={event => {
                                    let new_test = {...props.test}
                                    new_test.navigate[props.index].continue_if_fail = event.target.checked
                                    props.setTest(new_test)
                                }}
                                />
                            }
                            label="Continua aún fallando"
                            />
                        </Box>             
                        </Box>
                    </>
                    : <></>
                }

                {
                    props.step.event === "type" ? 
                    <>
                        <Box className="test_editor_form_step_row">
                        <Box className="test_editor_form_input" style={{"flex": 4}}>
                            <TextField
                            id="expresion"
                            label="Expresion"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step.expresion}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index].expresion = event.target.value
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        </Box>
                        <Box className="test_editor_form_step_row">
                        <Box className="test_editor_form_input">
                            <TextField
                            id="text"
                            label="Text"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step["text"]}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index].text = event.target.value
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        <Box className="test_editor_form_input">
                            <TextField
                            id="wait-to-load"
                            label="Espera para cargar"
                            type="number"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step["wait-to-load"] ? props.step["wait-to-load"] : 0}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index]["wait-to-load"] = event.target.value.length === 0 ? 0 : parseInt(event.target.value)
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        <Box className="test_editor_form_input">
                            <FormControlLabel
                            control={
                                <Switch
                                color="primary"
                                checked={props.step.extract}
                                onChange={event => {
                                    let new_test = {...props.test}
                                    new_test.navigate[props.index].extract = event.target.checked
                                    props.setTest(new_test)
                                }}
                                />
                            }
                            label="Capturar"
                            />
                        </Box>              
                        </Box>                              
                    </>
                    : <></>
                }

                {
                    props.step.event === "scroll" ? 
                    <>
                        <Box className="test_editor_form_step_row">
                        <Box className="test_editor_form_input">
                            <TextField
                            id="y"
                            label="Y"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step.y}
                            onChange={event => {
                                let new_test = {...props.test}
                                if (event.target.value === "end") {
                                new_test.navigate[props.index].y = event.target.value
                                } else {
                                new_test.navigate[props.index].y = parseInt(event.target.value)
                                }
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        <Box className="test_editor_form_input">
                            <TextField
                            id="wait-to-load"
                            label="Espera para cargar"
                            type="number"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step["wait-to-load"] ? props.step["wait-to-load"] : 0}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index]["wait-to-load"] = event.target.value.length === 0 ? 0 : parseInt(event.target.value)
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        <Box className="test_editor_form_input">
                            <FormControlLabel
                            control={
                                <Switch
                                color="primary"
                                checked={props.step.extract}
                                onChange={event => {
                                    let new_test = {...props.test}
                                    new_test.navigate[props.index].extract = event.target.checked
                                    props.setTest(new_test)
                                }}
                                />
                            }
                            label="Capturar"
                            />
                        </Box>              
                        </Box>                          
                    </>
                    : <></>
                }

                {
                    props.step.event === "file" ? 
                    <>
                        <Box className="test_editor_form_step_row">
                        <Box className="test_editor_form_input" style={{"flex": 4}}>
                            <TextField
                            id="expresion"
                            label="Expresion"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step.expresion}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index].expresion = event.target.value
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        </Box>
                        <Box className="test_editor_form_step_row">
                        <Box className="test_editor_form_input">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" color="primary">Tipo</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props.step.file}
                            label="type"
                            variant="outlined"
                            color="primary"
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index].file = event.target.value
                                props.setTest(new_test)
                            }}
                            >
                            <MenuItem value="video">Video</MenuItem>
                            <MenuItem value="sound">Sonido</MenuItem>
                            <MenuItem value="image">Imagen</MenuItem>
                            </Select>
                        </FormControl>  
                        </Box>
                        <Box className="test_editor_form_input">
                            <TextField
                            id="wait-to-load"
                            label="Espera para cargar"
                            type="number"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step["wait-to-load"] ? props.step["wait-to-load"] : 0}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index]["wait-to-load"] = event.target.value.length === 0 ? 0 : parseInt(event.target.value)
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        <Box className="test_editor_form_input">
                            <FormControlLabel
                            control={
                                <Switch
                                color="primary"
                                checked={props.step.extract}
                                onChange={event => {
                                    let new_test = {...props.test}
                                    new_test.navigate[props.index].extract = event.target.checked
                                    props.setTest(new_test)
                                }}
                                />
                            }
                            label="Capturar"
                            />
                        </Box>              
                        </Box>                       
                    </>
                    : <></>
                }

                {
                    props.step.event === "system_variable" ? 
                    <>
                        <Box className="test_editor_form_step_row">
                        <Box className="test_editor_form_input" style={{"flex": 4}}>
                            <TextField
                            id="expresion"
                            label="Expresion"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step.expresion}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index].expresion = event.target.value
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        </Box>
                        <Box className="test_editor_form_step_row">
                        <Box className="test_editor_form_input">
                            <TextField
                            id="variable_name"
                            label="Nombre de variable"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step.variable_name}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index].variable_name = event.target.value
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        <Box className="test_editor_form_input">
                            <TextField
                            id="wait-to-load"
                            label="Espera para cargar"
                            type="number"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step["wait-to-load"] ? props.step["wait-to-load"] : 0}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index]["wait-to-load"] = event.target.value.length === 0 ? 0 : parseInt(event.target.value)
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        <Box className="test_editor_form_input">
                            <FormControlLabel
                            control={
                                <Switch
                                color="primary"
                                checked={props.step.extract}
                                onChange={event => {
                                    let new_test = {...props.test}
                                    new_test.navigate[props.index].extract = event.target.checked
                                    props.setTest(new_test)
                                }}
                                />
                            }
                            label="Capturar"
                            />
                        </Box>              
                        </Box>                              
                    </>
                    : <></>
                }

                {
                    props.step.event === "status_variable" ? 
                    <>
                        <Box className="test_editor_form_step_row">
                        <Box className="test_editor_form_input" style={{"flex": 4}}>
                            <TextField
                            id="expresion"
                            label="Expresion"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step.expresion}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index].expresion = event.target.value
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        </Box>
                        <Box className="test_editor_form_step_row">
                        <Box className="test_editor_form_input">
                            <TextField
                            id="variable_name"
                            label="Nombre de variable"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step.variable_name}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index].variable_name = event.target.value
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        <Box className="test_editor_form_input">
                            <TextField
                            id="wait-to-load"
                            label="Espera para cargar"
                            type="number"
                            variant="outlined"
                            color="primary"
                            fullWidth
                            value={props.step["wait-to-load"] ? props.step["wait-to-load"] : 0}
                            onChange={event => {
                                let new_test = {...props.test}
                                new_test.navigate[props.index]["wait-to-load"] = event.target.value.length === 0 ? 0 : parseInt(event.target.value)
                                props.setTest(new_test)
                            }}
                            />
                        </Box>
                        <Box className="test_editor_form_input">
                            <FormControlLabel
                            control={
                                <Switch
                                color="primary"
                                checked={props.step.extract}
                                onChange={event => {
                                    let new_test = {...props.test}
                                    new_test.navigate[props.index].extract = event.target.checked
                                    props.setTest(new_test)
                                }}
                                />
                            }
                            label="Capturar"
                            />
                        </Box>              
                        </Box>                              
                    </>
                    : <></>
                }

                {
                    props.step.event === "zoom_out" ? 
                    <>
                        <Box className="test_editor_form_step_row">
                            <Box className="test_editor_form_input" style={{"flex": 4}}>
                                <TextField
                                id="expresion"
                                label="Expresion"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                value={props.step.expresion}
                                onChange={event => {
                                    let new_test = {...props.test}
                                    new_test.navigate[props.index].expresion = event.target.value
                                    props.setTest(new_test)
                                }}
                                />
                            </Box>
                        </Box>
                        <Box className="test_editor_form_step_row">
                            <Box className="test_editor_form_input">
                                <TextField
                                id="wait-to-load"
                                label="Espera para cargar"
                            type="number"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                value={props.step["wait-to-load"] ? props.step["wait-to-load"] : 0}
                                onChange={event => {
                                    let new_test = {...props.test}
                                    new_test.navigate[props.index]["wait-to-load"] = event.target.value.length === 0 ? 0 : parseInt(event.target.value)
                                    props.setTest(new_test)
                                }}
                                />
                            </Box>
                            <Box className="test_editor_form_input">
                                <FormControlLabel
                                control={
                                    <Switch
                                    color="primary"
                                    checked={props.step.extract}
                                    onChange={event => {
                                        let new_test = {...props.test}
                                        new_test.navigate[props.index].extract = event.target.checked
                                        props.setTest(new_test)
                                    }}
                                    />
                                }
                                label="Capturar"
                                />
                            </Box>              
                        </Box>                       
                    </>
                    : <></>
                }

                {
                    props.step.event === "key_press" ? 
                    <>
                        <Box className="test_editor_form_step_row">
                            <Box className="test_editor_form_input">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" color="primary">Tecla</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={props.step.key}
                                        label="tecla"
                                        variant="outlined"
                                        color="primary"
                                        onChange={event => {
                                            let new_test = {...props.test}
                                            new_test.navigate[props.index].key = event.target.value
                                            props.setTest(new_test)
                                        }}
                                    >
                                        <MenuItem value="enter">Intro</MenuItem>
                                    </Select>
                                </FormControl>  
                            </Box>
                            <Box className="test_editor_form_input">
                                <TextField
                                id="wait-to-load"
                                label="Espera para cargar"
                            type="number"
                                variant="outlined"
                                color="primary"
                                fullWidth
                                value={props.step["wait-to-load"] ? props.step["wait-to-load"] : 0}
                                onChange={event => {
                                    let new_test = {...props.test}
                                    new_test.navigate[props.index]["wait-to-load"] = event.target.value.length === 0 ? 0 : parseInt(event.target.value)
                                    props.setTest(new_test)
                                }}
                                />
                            </Box>
                            <Box className="test_editor_form_input">
                                <FormControlLabel
                                control={
                                    <Switch
                                    color="primary"
                                    checked={props.step.extract}
                                    onChange={event => {
                                        let new_test = {...props.test}
                                        new_test.navigate[props.index].extract = event.target.checked
                                        props.setTest(new_test)
                                    }}
                                    />
                                }
                                label="Capturar"
                                />
                            </Box>              
                        </Box>                       
                    </>
                    : <></>
                }

                {
                    props.step.extract && Object.keys(props.test.screenshots).includes(props.index.toString())
                    ?
                        <img src={SCREENSHOTS + "/screenshots/" + props.test.screenshots[props.index.toString()].slice(-1)[0] + "?no-cache-string=" + Math.random()} alt="Captura de pantalla del paso"/>
                    :
                        <></>
                    
                }

            </Box>
            <Box>
                <Button
                    color="secondary"
                    id={"basic-menu-" + props.index}
                    aria-controls={open ? "basic-menu-" + props.index : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <AddIcon/> 
                </Button>
                
                <Menu
                    id={"basic-menu-" + props.index}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': "basic-menu-" + props.index,
                    }}
                >
                    <MenuItem onClick={() => {props.addStep("go",props.index + 1); handleClose()}}>Ir a una URL</MenuItem>
                    <MenuItem onClick={() => {props.addStep("click",props.index + 1); handleClose()}}>Hacer clic</MenuItem>
                    <MenuItem onClick={() => {props.addStep("type",props.index + 1); handleClose()}}>Escribir</MenuItem>
                    <MenuItem onClick={() => {props.addStep("scroll",props.index + 1); handleClose()}}>Hacer scroll en la página</MenuItem>
                    <MenuItem onClick={() => {props.addStep("file",props.index + 1); handleClose()}}>Subir un archivo</MenuItem>
                    <MenuItem onClick={() => {props.addStep("system_variable",props.index + 1); handleClose()}}>Actualizar una variable de sistema</MenuItem>
                    <MenuItem onClick={() => {props.addStep("status_variable",props.index + 1); handleClose()}}>Actualizar una variable de estado</MenuItem>
                    <MenuItem onClick={() => {props.addStep("zoom_out",props.index + 1); handleClose()}}>Hacer zoom out</MenuItem>
                    <MenuItem onClick={() => {props.addStep("key_press",props.index + 1); handleClose()}}>Pulsar una tecla</MenuItem>
                </Menu>
            </Box>
        </Box>
    )
}

export default Steps