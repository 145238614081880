import React, {useState, MouseEvent} from "react";
import {
    TableRow,
    TableCell,
    IconButton,
    Box,
    Tooltip,
    Chip,
    Avatar,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import HistoryIcon from '@mui/icons-material/History';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import es from 'javascript-time-ago/locale/es'
import TimeAgo from 'javascript-time-ago'

import LogModal from "./LogModal";

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL


TimeAgo.addLocale(es)
const timeAgo = new TimeAgo('en-US')

const ListRow = (props) => {

    const [log_modal_open, setLogModalOpen] = useState(false)

    const [contextMenu, setContextMenu] = useState(null)

    const getUserName = (username) => {
        if (username in props.users)
          return props.users[username].name
        return username
    }

    const handleContextMenu = (event: MouseEvent) => {
        event.preventDefault();
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: event.clientX + 2,
                mouseY: event.clientY - 6,
              }
            : null,
        );
      };
    
      const cloneTest = () => {

        const createTest = (test) => {
            // var test = {...props.test}
            test.name = test.name + " - Copia"
            test.screenshots = {}
            test.lock = {lock: false}
            test.last_check = 0
            test.log = ""
            var body = test
            var headers = AuthHeader()
            headers.append("Content-Type", "application/json");    
            fetch(API + "/tests/", 
                    {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(body),
                    redirect: 'follow'
                    }
            )
            .then(response => response.json())
            .then(result => {
                if (result.status === "ok") {
                    window.open(window.location.origin +  "/test/" + result.id, "_blank");
                }
            })
            .catch(error => console.log('error', error));
        } 

        fetch(API + "/tests/" + props.test["_id"], 
            {
              method: 'GET',
              headers: AuthHeader()
            }
        )
        .then((response) => {            
          if (response.status === 200) {
            return response.json()
          } else if (response.status === 401) {            
            return null
          }
        })
        .then((data) => {
            createTest(data)
        })

        
        setContextMenu(null);
      };

      const archiveTest = () => {      
          var body = {
            "archived": !props.test["archived"]
          }
          var headers = AuthHeader()
          headers.append("Content-Type", "application/json");
          // Do the request
          fetch(API + "/tests/" + props.test["_id"] + "/", 
                {
                  method: 'PATCH',
                  headers: headers,
                  body: JSON.stringify(body),
                  redirect: 'follow'
                }
          )
          .then(response => {            
            if (response.status === 200) {
              return response.json()
            } else if (response.status === 401) {       
            //   setAlertCode("fail")
            //   setShowAlert(true)
            //   setTimeout(() => {
            //     setShowAlert(false)
            //   }, 2000)     
              return null
            }
          })
          .then(data => {
            if (data) {
            //   setAlertCode(data.status === "ok" ? "ok" : "fail")
            //   setShowAlert(true)
            //   setTimeout(() => {
            //     setShowAlert(false)
            //     if (!test.archived) window.close()
            //   }, 2000)
            }        
          })
        }

    return (
        <>
        {
            log_modal_open ? 
                <LogModal
                    log={props.test.log}
                    test_id={props.test._id}
                    open={log_modal_open}
                    close={setLogModalOpen}
                /> : <></>
        }
        <TableRow hover={true} key={props.test["_id"]} onContextMenu={handleContextMenu}>
            <Menu
                open={contextMenu !== null}
                onClose={() => {setContextMenu(null)}}
                anchorReference="anchorPosition"
                anchorPosition={
                contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
                }
            >
                <MenuItem onClick={archiveTest}>
                    <ListItemIcon>
                        {props.test["archived"] ? <UnarchiveIcon fontSize="small"/> : <ArchiveIcon fontSize="small"/>}
                    </ListItemIcon>
                    <ListItemText>{props.test["archived"] ? "Desarchivar" : "Archivar"}</ListItemText>
                </MenuItem>
                <MenuItem onClick={cloneTest}>
                    <ListItemIcon>
                        <ContentCopyIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Crear una copia</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {window.open("/history/" + props.test["_id"], '_blank').focus()}}>
                    <ListItemIcon>
                        <HistoryIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Histórico</ListItemText>
                </MenuItem>
            </Menu>
            <TableCell align='center'>
                { props.test.status === "ok" ? <Box className="status_flag status_flag_ok" onClick={() => setLogModalOpen(!log_modal_open)}>PASA</Box> : <></> }
                { props.test.status === "alert" ? <Box className="status_flag status_flag_alert" onClick={() => setLogModalOpen(!log_modal_open)}>ALERTA</Box> : <></> }
                { props.test.status === "fail" ? <Box className="status_flag status_flag_fail" onClick={() => setLogModalOpen(!log_modal_open)}>FALLO</Box> : <></> }
                { props.test.status === "queue" ? <Box className="status_flag status_flag_waiting" onClick={() => setLogModalOpen(!log_modal_open)}>EN COLA</Box> : <></> }
                { props.test.status === "running" ? <Box className="status_flag status_flag_waiting" onClick={() => setLogModalOpen(!log_modal_open)}>CORRIENDO</Box> : <></> }
            </TableCell>
            <TableCell align='center' style={{"color": props.test.alerts > 0 ? "red" : ""}}>
                { props.test.alerts }
            </TableCell>
            <TableCell align='center'>
                { props.test.type }
            </TableCell>
            <TableCell align='center'>{props.test.engine}</TableCell>
            <TableCell>
                    {props.test.app}
                    {
                        props.test.navigate[0] && props.test.navigate[0].url ?

                            <Tooltip title="Abrir web">
                                <IconButton onClick={() => {window.open(props.test.navigate[0].url, '_blank').focus()}}>
                                    <OpenInNewIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        : <></>
                    
                    }
            </TableCell>
            <TableCell>{props.test.name}</TableCell>
            <TableCell align='center'>{props.test.campaign === "fve" ? "FVE" : (props.test.campaign === "training" ? "Form." : (props.test.campaign === "no" ? "No" : props.test.campaign))}</TableCell>
            <TableCell align='center'>{props.test.ui_depends_on_os_version ? "Sí" : "No"}</TableCell>
            <TableCell align='center'>{props.test.automatic ? "Sí" : "No"}</TableCell>
            <TableCell align='center'>{props.test.user_interaction ? "Sí" : "No"}</TableCell>
            <TableCell>
                {
                typeof props.test.last_check === "string" || props.test.last_check instanceof String
                ? 
                    props.test.last_check
                :
                    timeAgo.format(props.test.last_check*1000)
                }
            </TableCell>
            <TableCell>
                {
                    props.test.lock.lock ?
                        <Chip
                            avatar={<Avatar alt={getUserName(props.test.lock.username)} src={"/images/avatar/" + props.test.lock.username + ".jpg"} />}
                            label={getUserName(props.test.lock.username)}
                            variant="outlined"
                        />                                
                    :
                        <>
                            <Tooltip title="Ver alertas">
                                <IconButton onClick={() => {window.open("/review/" + props.test["_id"], '_blank').focus()}}>
                                    <NotificationsIcon/>
                                </IconButton>
                            </Tooltip>                                                           
                            <Tooltip title="Editar test">
                                <IconButton onClick={() => {window.open("/test/" + props.test["_id"], '_blank').focus()}}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                            
                            {
                                props.test.engine === "manual" ?
                                    <Tooltip title="Actualizar tiempo">
                                        <IconButton onClick={() => {props.updateTime(props.test["_id"], props.test.name)}}> <CheckCircleOutlineIcon /> </IconButton>
                                    </Tooltip>
                                :
                                    <Tooltip title="Ejecutar test">
                                        {
                                            props.test.status === "queue" || props.test.status === "running" || !props.test.run_button_enable
                                            ? 
                                                <IconButton disabled> <CloudDownloadIcon /> </IconButton>
                                            :
                                                <IconButton onClick={() => {props.scrap(props.test["_id"], props.test.name)}}> <CloudDownloadIcon /> </IconButton>
                                        }
                                    </Tooltip>
                            }
                        </>
                }
            </TableCell>
        </TableRow>
        </>
    )

}

export default ListRow