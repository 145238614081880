import { Box, Alert, LinearProgress, Tooltip, IconButton } from '@mui/material';
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import SaveIcon from '@mui/icons-material/Save';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import Steps from '../components/test/Steps';
import Definition from '../components/test/Definition';
import Activities from '../components/test/Activities';
import Campaign from '../components/test/Campaign';
import FourOOne from '../components/general/FourOOne';
import EngineAlert from '../components/general/EngineAlert';

import "./Test.css"

import AuthHeader from '../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const Test = (props) => {

  const { test_id } = useParams()
  const [status, setStatus] = useState("loading")
  const [test, setTest] = useState()
  const [show_alert, setShowAlert] = useState(false)
  const [alert_code, setAlertCode] = useState("")
  const [engine_id, setEngineId] = useState("engine_1")

  useEffect(() => {
    props.setScrumbs(status === "loading" ? "Test" : "Test > " + test.name)
  }, [test, status, props])

  useEffect(() => {
    fetch(API + "/tests/" + test_id, 
        {
          method: 'GET',
          headers: AuthHeader()
        }
    )
    .then((response) => {            
      if (response.status === 200) {
          return response.json()
      } else if (response.status === 401) {
          setStatus("401")                
          return null
      }
    })
    .then((data) => {
      if (data) {
        setTest(data)
        setStatus("200")
      } 
      
    })
  }, [test_id])
  
  useEffect(() => {
    const lockResource = (lock) => {
      var headers = AuthHeader()
      headers.append("Content-Type", "application/json");
      fetch(API + "/tests/" + test_id, 
        {
          method: 'PATCH',
          headers: headers,
          body: JSON.stringify({"lock": {"lock": lock}}),
        }
      )
      .then(response => response.json())
      .then(result => {
      })
      .catch(error => console.log('error', error));
    }

    // window.addEventListener('onunload', (event) => {
    //   lockResource(false)
    // });    
    window.onbeforeunload = function () {
      lockResource(false)
    };

    lockResource(true)

    return () => lockResource(false)
  }, [test_id])

  const saveTest = () => {
    if (!checkSafeToSave()) {
      setAlertCode("fail")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else {
      var body = test
      var headers = AuthHeader()
      headers.append("Content-Type", "application/json");
      // Do the request
      fetch(API + "/tests/" + test_id + "/", 
            {
              method: 'PUT',
              headers: headers,
              body: JSON.stringify(body),
              redirect: 'follow'
            }
      )
      .then(response => {            
        if (response.status === 200) {
          return response.json()
        } else if (response.status === 401) {       
          setAlertCode("fail")
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(false)
          }, 2000)     
          return null
        }
      })
      .then(data => {
        if (data) {
          setAlertCode(data.status === "ok" ? "ok" : "fail")
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(false)
          }, 2000)
        }        
      })
    }
  }

  const checkSafeToSave = () => {
    let safe = true
    safe = safe && test.name.length > 0
    safe = safe && !test.name.includes(":")
    return safe
  }
  
  const scrap = () => {
    const body = {
      "test_ids": [test_id],
      "engine": engine_id
    }
    var headers = AuthHeader()
    headers.append("Content-Type", "application/json");
    fetch(API + "/tasks",
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(body)
        }
    )
    .then((response) => response.json())
    .then((data) => {})
  }

  return (
    <Box className="test_page">
      { status === "loading" 
      ?
          <Box sx={{ width: '100%', marginTop: 5 }}>
              <LinearProgress color="secondary" />
          </Box> 
      : <></> }
      { status === "401" 
      ?
          <FourOOne/>
      : <></> }
      { status === "200" 
      ?
        <>
          <Box className='actions_row'>
            <Box className="actions_row_left_container">              
    
            </Box>
            <Box className="actions_row_right_container">
                  <Tooltip title="Guardar cambios"> 
                      <IconButton onClick={() => {
                          saveTest()
                      }}>
                          <SaveIcon/>
                      </IconButton>
                  </Tooltip>
                  <Tooltip title="Ver alertas"> 
                            <IconButton onClick={() => {
                          window.location.href = "/review/" + test_id
                      }}>
                          <NotificationsIcon/>
                      </IconButton>
                  </Tooltip>
                  <Tooltip title="Correr test"> 
                      <IconButton disabled={test.engine === "manual" || !test.run_button_enable} onClick={() => {
                          scrap()
                      }}>
                          <CloudDownloadIcon/>
                      </IconButton>
                  </Tooltip>
                  <EngineAlert engine_id={engine_id} setEngineId={setEngineId} user={props.user}/>
            </Box>
          </Box>

          <Box className="test_container">
            <Box className="test_section_title"><h2>Definición</h2></Box>
            <Definition test={test} setTest={setTest}/>
            <Box className="test_section_title"><h2>Actividades afectadas</h2></Box>
            <Activities test={test} setTest={setTest}/>
            <Box className="test_section_title"><h2>Campaña activa</h2></Box>
            <Campaign test={test} setTest={setTest}/>
            <Box className="test_section_title"><h2>Pasos</h2></Box>
            <Steps test={test} setTest={setTest}/>
          </Box>
        </>
      : <></> }   

      { show_alert ? 
        <Box className="alert_container">
            <Alert severity={alert_code === "ok" ? "success" : "error"}>
            {alert_code === "ok" ? "Guardado" : "Algo ha ido mal"}
            </Alert>
        </Box>
        : <></> } 
    </Box>
  )
}
export default Test