import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Box,
    LinearProgress,
} from '@mui/material';
import React, { useState, useEffect} from "react";

import AuthHeader from '../../resources/AuthHeader';
import ListRow from './ListRow';

var first_app_id = ""
const API = process.env.REACT_APP_API_URL

const List = (props) => {

  const [users, setUsers] = useState({})

  useEffect(() => {
    const handleShortcuts = (event) => {
      if (first_app_id !== "") {
        if (event.ctrlKey && event.code === "KeyA") {
          event.preventDefault();
          window.open("/review/" + first_app_id, '_blank').focus();
        }
      }
    }

    window.addEventListener("keydown", handleShortcuts)

    return () => {
        window.removeEventListener("keydown", handleShortcuts);
    }
  }, [])

  useEffect(() => {
    loadUsers()
  }, [])

  const loadUsers = () => {
    fetch(API + "/users?format=dictionary", 
            {
            method: 'GET',
            headers: AuthHeader()
            }
    )
    .then((response) => response.json())
    .then((data) => {
        setUsers(data)
    })
    .catch((error) => console.log(error))
  }

  const showTest = (test, index) => {
    if (index === 0) first_app_id = ""
    let show = true
    // Search words
    // show = show && props.search_words === ""
    let words = props.search_words.split(" ")
    for (let i = 0; i < words.length; i++) {
      if (Object.keys(test).includes("app"))
        show = show && (test.app.toLowerCase().includes(words[i]) || test.name.toLowerCase().includes(words[i]))
      else
        show = show && test.name.toLowerCase().includes(words[i])
    }
    // Filters
    for (let i = 0; i < props.search_filters.length; i++) {
      let filter = props.search_filters[i]
      if (filter.key in test) {
        if (filter.operator === "=") {
          if (test[filter.key] !== filter.value)
            show = show && false
        } else if (filter.operator === ">") {
          if (test[filter.key] <= filter.value)
            show = show && false
        } else if (filter.operator === "<") {
          if (test[filter.key] >= filter.value)
            show = show && false
        }
      }
    }
    if (first_app_id === "" && show) first_app_id = test["_id"]
    return show
  }

    return (
      <>
        {
          props.tests.length === 0
          ? 
            <Box sx={{ width: '100%', marginTop: 5 }}>
              <LinearProgress color="secondary" />
            </Box> 
          :
            <TableContainer sx={{ height: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align='center' sx={{width: 100}}>Estado</TableCell>
                    <TableCell align='center' sx={{width: 30}}>Alertas</TableCell>
                    <TableCell align='center' sx={{width: 50}}>Tipo</TableCell>
                    <TableCell align='center' sx={{width: 50}}>Motor</TableCell>
                    <TableCell sx={{width: 250}}>App</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell align='center' sx={{width: 100}}>Campaña</TableCell>
                    <TableCell align='center' sx={{width: 100}}>UI varía</TableCell>
                    <TableCell align='center' sx={{width: 50}}>Automático</TableCell>
                    <TableCell align='center' sx={{width: 50}}>Interacción</TableCell>
                    <TableCell sx={{width: 170}}>Ultima ejecución</TableCell>
                    <TableCell align='center' sx={{width: 200}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    props.tests.map((test, index) => {
                      if (showTest(test, index))
                        return <ListRow users={users} test={test} scrap={props.scrap} updateTime={props.updateTime} key={test["_id"]}/>
                      else return (<></>)
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
        }
      </>
    );
}

export default List;