import {
  Button,
  Modal,
  Box,
  TextField,
  Alert
 } from '@mui/material';
import React, { useState, useEffect } from "react";

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL
const ACTIVITY_TEMPLATE = {
    code: "",
    name: "",
    web: "",
    drive: "",
    coverage: "-1",
    notes: ""
}

const Editor = (props) => {

  const [activity, setActivity] = useState(ACTIVITY_TEMPLATE)
  const [show_alert, setShowAlert] = useState(false)
  const [alert_code, setAlertCode] = useState("")

  useEffect(() => {
    if (props.selected_activity === null) {
      setActivity(ACTIVITY_TEMPLATE)
    } else {
      // let activity = props.selected_activity
      // activity["code"] = activity["_id"]
      setActivity(props.selected_activity)
    }
  }, [props.selected_activity])
 
  const saveActivity = () => {
    if (!checkSafeToSave()) {
      setAlertCode("fail")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else {
      var body = {...activity}
      delete body["_id"]
      var headers = AuthHeader()
      headers.append("Content-Type", "application/json");
      fetch(API + "/activities" + (props.selected_activity === null ? "" : ("/" + activity["_id"])), 
            {
              method: props.selected_activity === null ? 'POST' : 'PUT',
              headers: headers,
              body: JSON.stringify(body),
            }
      )
      .then(response => response.json())
      .then(result => {
        if (result.status === "ok" && props.selected_activity === null) {
          props.close(false)
        }
        setAlertCode(result.status === "ok" ? "ok" : "fail")
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 2000)
      })
    }
 
  }

  const checkSafeToSave = () => {
    let safe = true
    safe = safe && activity["name"].length > 0
    safe = safe && !activity["name"].includes(":")
    return safe
  }

  return (
    <Modal
        open={props.open}
        onClose={() => {props.close(false)}}
      >
        <Box className='activity_editor_modal_container'>
          <Box className="activity_editor_title">
            <h2>Nueva actividad</h2>
          </Box>

          <Box className="activity_editor_form">
            <Box className="activity_editor_form_row">
              <Box className="activity_editor_form_input">
                <TextField
                  id="name"
                  label="Nombre"
                  variant="outlined"
                    color="primary"
                  fullWidth
                  value={activity.name}
                  onChange={event => {
                    let new_activity = {...activity}
                    new_activity.name = event.target.value
                    setActivity(new_activity)
                  }}/>
              </Box>
            </Box>
            <Box className="activity_editor_form_row">
              <Box className="activity_editor_form_input">
                <TextField
                  id="code"
                  label="Código de contenidos"
                  variant="outlined"
                    color="primary"
                  fullWidth
                  value={activity["code"]}
                  onChange={event => {
                    let new_activity = {...activity}
                    new_activity["code"] = event.target.value
                    setActivity(new_activity)
                  }}/>
              </Box>
            </Box>
            <Box className="activity_editor_form_row">
              <Box className="activity_editor_form_input">
                <TextField
                  id="web"
                  label="URL web"
                  variant="outlined"
                    color="primary"
                  fullWidth
                  value={activity.web}
                  onChange={event => {
                    let new_activity = {...activity}
                    new_activity.web = event.target.value
                    setActivity(new_activity)
                  }}/>
              </Box>
            </Box>
            <Box className="activity_editor_form_row">
              <Box className="activity_editor_form_input">
                <TextField
                  id="web"
                  label="URL Google Docs"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  value={activity.drive}
                  onChange={event => {
                    let new_activity = {...activity}
                    new_activity.drive = event.target.value
                    setActivity(new_activity)
                  }}/>
              </Box>
            </Box>
            <Box className="activity_editor_form_row">
              <Box className="activity_editor_form_input">
                <TextField
                  id="coverage"
                  label="Porcentaje de cobertura"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  value={activity.coverage}
                  onChange={event => {
                    let new_activity = {...activity}
                    new_activity.coverage = event.target.value
                    setActivity(new_activity)
                  }}
                />
              </Box>
            </Box>
            <Box className="activity_editor_form_row">
              <Box className="activity_editor_form_input">
                <TextField
                  id="notes"
                  label="Notas"
                  variant="outlined"
                  color="primary"
                  multiline
                  minRows={5}
                  fullWidth
                  value={activity.notes}
                  onChange={event => {
                    let new_activity = {...activity}
                    new_activity.notes = event.target.value
                    setActivity(new_activity)
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box className="activity_editor_buttons_row">
            <Button variant="contained" color="secondary" onClick={() => {saveActivity()}}>
              { props.selected_activity === null ? "Crear" : "Guardar"}
            </Button>
          </Box>
          
          { show_alert ? 
            <Box className="alert_container">
              <Alert severity={alert_code === "ok" ? "success" : "error"}>
                {alert_code === "ok" ? "Guardado" : "Algo ha ido mal"}
              </Alert>
            </Box>
          : <></> }

        </Box>        
    </Modal>
  )
}

export default Editor