import { Chip, Stack, Tooltip } from '@mui/material';
import React, { useEffect, useState } from "react";
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CloudOffIcon from '@mui/icons-material/CloudOff';

import "./EngineAlert.css"

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_MAIN_SUBDOMAIN_API_URL

const EngineAlert = (props) => {

  const [engine, setEngine] = useState(true)

  const loadEngine = (engine_id) => {
    fetch(API + "/engines/" + engine_id, 
      {
        method: 'GET',
        headers: AuthHeader()
      }
    )
    .then((response) => {            
      if (response.status === 200) {
        return response.json()
      } else if (response.status === 401) {            
        return null
      }
    })
    .then((data) => {
        setEngine(data)
    })
  }

   useEffect(() => {
    loadEngine(props.engine_id)
    const interval_id = setInterval(() => {
      loadEngine(props.engine_id)
    }, 2000)
    return () => {
      clearInterval(interval_id)
    }
  }, [props.engine_id])

  const changeEngine = () => {
    let engine_id = props.engine_id !== "engine_1" ? "engine_1" : "engine_" + props.user.username
    props.setEngineId(engine_id)
    var date = new Date();
    date.setTime(date.getTime() + (365*24*60*60*1000));
    document.cookie = "engine_id=" + engine_id + "; expires=" + date.toUTCString();
  }

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title="Cambiar engine">
        <Chip
          icon={((Date.now()/1000) - engine.heartbeat) < 5 ? <CloudQueueIcon/> : <CloudOffIcon/> }
          label={engine.name}
          variant="outlined"
          onClick={() => {
            changeEngine()
          }}
        />
      </Tooltip>
    </Stack>
  )
}

export default EngineAlert