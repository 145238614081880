import { Box } from '@mui/material';
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import Alerts from '../components/review/Alerts';
import AlertBox from '../components/review/AlertBox';
import Report from '../components/review/Report';
import ActivitiyViewer from '../components/review/ActivitiyViewer';
import ActivitiesButtons from '../components/review/ActivitiesButtons';
import Procesess from '../components/review/Procesess';

import "./Test.css"
import "./Review.css"

import AuthHeader from '../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const Review = (props) => {

  const { test_id } = useParams()
  const [allow_review, setAllowReview] = useState(false);
  const [alert_to_review, setAlertToReview] = React.useState("");
  const [users, setUsers] = useState({})
  const [activity_to_review, setActivityToReview] = React.useState({"web":"https://digicraft.fundacionvodafone.es"});
  const [open_report, setOpenReport] =  React.useState(false);

  useEffect(() => {
    fetch(API + "/tests/" + test_id, 
        {
          method: 'GET',
          headers: AuthHeader()
        }
    )
    .then((response) => response.json())
    .then((data) => {
        setAllowReview(data.activities.length > 0)
        props.setScrumbs("Revisar > " + data.name)
    })
    .catch((error) => console.log(error))
  }, [test_id, props])
  
  useEffect(() => {
    const lockResource = (lock) => {
      var headers = AuthHeader()
      headers.append("Content-Type", "application/json");
      fetch(API + "/tests/" + test_id, 
        {
          method: 'PATCH',
          headers: headers,
          body: JSON.stringify({"lock": {"lock": lock}}),
        }
      )
      .then(response => response.json())
      .then(result => {
      })
      .catch(error => console.log('error', error));
    }

    // window.addEventListener('beforeunload', function (e) {
    //   lockResource(false)
    // });  
    window.onbeforeunload = function () {
      lockResource(false)
    };  

    lockResource(true)

    return () => lockResource(false)
  }, [test_id])
  
  useEffect(() => {
    fetch(API + "/users?format=dictionary", 
            {
            method: 'GET',
            headers: AuthHeader()
            }
    )
    .then((response) => response.json())
    .then((data) => {
        setUsers(data)
    })
  }, [])

  return (

    <Box>
      {
        alert_to_review === "" ?
          <Box className="alerts_page">
            <Alerts
              test_id={test_id}
              allow_review={allow_review}
              setAlertToReview={setAlertToReview}
              users={users}
            />     
          </Box>
        :
          <Box className="review_page">
            <Box className="review_left_column">
              <Report
                open={open_report}
                setOpen={setOpenReport}
                alert={alert_to_review}
                activity={activity_to_review}
              />
              <AlertBox
                reviewing={true}
                allow_review={null}
                alert={alert_to_review}
                users={users}
                setAlertToReview={setAlertToReview}
                onArchived={() => {setAlertToReview("")}}
                setOpenReport={setOpenReport}
              />
              <Procesess
                open_report={open_report}
                activity_code={activity_to_review.code}
              />
            </Box>
            <Box className="review_right_column">
              <ActivitiesButtons
                test_id={test_id}
                setActivityToReview={setActivityToReview}
              />
              <ActivitiyViewer
                activity={activity_to_review}
              />
            </Box>      
          </Box>
      }
    </Box>
  )
}

export default Review