import {
  Modal,
  Box,
  TextField,
  Button,
  Alert
} from '@mui/material';

import React, { useState } from "react";

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const NewTest = (props) => {

  const [test, setTest] = useState({
    "name": "",
    "activities": [],
    "type": "web",
    "engine": "chrome",
    "navigate": [],
    "campaign": "no",
    "ui_depends_on_os_version": false,
    "pre_test": "",
    "adblocker": "no",
    "user_interaction": false,
    "run_button_enable": true,
    "archived": false,
    "automatic": false,
    "app": "",
    "screenshots": {},
    "last_check": 0,
    "lock": {"lock": false},
    "log": "",
    "status": "ok",
    "fail_step": -1,
    "variables": {}

  })
  const [show_alert, setShowAlert] = useState(false)
  const [alert_code, setAlertCode] = useState("")

  const saveTest = () => {

    if (!checkSafeToSave()) {
      setAlertCode("fail")
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    } else {
      var body = test
      var headers = AuthHeader()
      headers.append("Content-Type", "application/json");    
      fetch(API + "/tests/", 
            {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(body),
              redirect: 'follow'
            }
      )
      .then(response => response.json())
      .then(result => {
        setAlertCode(result.status === "ok" ? "ok" : "fail")
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 2000)
        if (result.status === "ok") {
          props.openTestEditor(result.id)
        }
      })
      .catch(error => console.log('error', error));
    }
  }

  const checkSafeToSave = () => {
    let safe = true
    safe = safe && test.name.length > 0
    //safe = safe && !test.name.includes(":")
    return safe
  }

  const setName = (name) => {
    let new_test = {...test}
    new_test.name = name
    setTest(new_test)
  }

  return (
    <Modal
        open={props.open}
        onClose={() => {props.close(false)}}
      >
        <Box className='new_test_modal_container'>
          <Box className="new_test_title">
            <h2>Nuevo test</h2>
          </Box>
          <Box className="new_test_form">
            <Box className="new_test_form_row">
              <Box className="new_test_form_input">
                <TextField
                  id="name"
                  label="Nombre"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  value={test.name}
                  onChange={event => {
                    setName(event.target.value)
                  }}
                />
              </Box>
            </Box>
                      
          </Box>
          <Box className="new_test_buttons_row">
            <Button variant="contained" color="secondary" onClick={() => {saveTest()}}>Crear</Button>
          </Box>
          { show_alert ? 
            <Box className="alert_container">
              <Alert severity={alert_code === "ok" ? "success" : "error"}>
                {alert_code === "ok" ? "Guardado" : "Algo ha ido mal"}
              </Alert>
            </Box>
          : <></> }
        </Box>
    </Modal>
  )
}

export default NewTest