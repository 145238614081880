import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    TableContainer,
    Box,
    LinearProgress,
    Tooltip
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import React, { useState, useEffect } from "react";

import FourOOne from '../general/FourOOne';

import AuthHeader from '../../resources/AuthHeader';
const API = process.env.REACT_APP_API_URL

const List = (props) => {

  const [status, setStatus] = useState("loading")
  const [activities, setActivities] = useState([])

  useEffect(() => {
    loadActivities()    
  }, [props.open_activity_editor])

  const loadActivities = () => {
    fetch(API + "/activities", 
          {
            method: 'GET',
            headers: AuthHeader()
          }
    )
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      } else if (response.status === 401) {
          setStatus("401")                
          return null
      }
    })
    .then((data) => {
      if (data) {
        setActivities(data)
        setStatus("200")
      }
    })
  }

    return (
      <>
           { status === "loading" 
            ?
                <Box sx={{ width: '100%', marginTop: 5 }}>
                    <LinearProgress color="secondary" />
                </Box> 
            : <></> }
            { status === "401" 
            ?
                <FourOOne/>
            : <></> }
            { status === "200" 
            ?
              <>
                <TableContainer sx={{ flex: 1 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{width: 100}}>Código</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell sx={{width: 60}}>Cobertura</TableCell>
                        <TableCell sx={{width: 200}}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        activities.map(activity => {
                          return (
                            props.search_query === "" || activity.name.toLowerCase().includes(props.search_query.toLowerCase()) || activity.code.toLowerCase().includes(props.search_query.toLowerCase()) ?
                              <TableRow hover={true} height={75} key={activity["_id"]}>
                                <TableCell align='center'>
                                  {activity["code"]}
                                </TableCell>
                                <TableCell>
                                  {activity.name}                       
                                </TableCell>
                                <TableCell>
                                  {activity.coverage}                       
                                </TableCell>
                                <TableCell>
                                  <Tooltip title="Editar"> 
                                    <IconButton onClick={() => {
                                        props.setSelectedActivity(activity)
                                        props.setOpenActivityEditor(true)
                                      }}>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Reportar actividad"> 
                                    <IconButton onClick={() => {
                                      props.setSelectedActivity(activity)
                                      props.setOpenReport(true)
                                    }}>
                                      <ReportProblemIcon/>
                                    </IconButton>
                                  </Tooltip>   
                                  <Tooltip title="Abrir actividad"> 
                                    <IconButton onClick={() => {window.open(activity.web, '_blank').focus()}}>
                                      <OpenInNewIcon/>
                                    </IconButton>
                                  </Tooltip>  
                                  <Tooltip title="Copiar enlace de actividad"> 
                                    <IconButton onClick={() => {
                                      navigator.clipboard.writeText(activity.web);
                                      props.showAlert(true)
                                      setTimeout(() => props.showAlert(false), 5000)
                                    }}>
                                      <ContentCopyIcon/>
                                    </IconButton>
                                  </Tooltip>
                                </TableCell>
                              </TableRow>                              
                            : <></>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            : <></>}    
        </>   

    );
}

export default List;